import { ReactNode } from 'react';

import Dropdown from 'components/Dropdown';
import LoadingSpinner from 'components/LoadingSpinner';
import { FEATURE_FLAG_DISCOVERY_LISTS_VIEW } from 'constants/feature-flag-keys';
import { useFeatureToggle } from 'hooks/FeatureFlag/UseFeatureToggle';

import CloseButton from './Components/CloseButton';
import Styled from './DiscoveryDropdown.style';

/**
 */
const DefaultDropdownMenuItems = (props: {
	onAddCampaign: () => void;
	onAddList: () => void;
	onClose: () => void;
	isLoadingLists: boolean;
	isLoadingCampaigns: boolean;
	children?: ReactNode;
}) => {
	const [isEnabled] = useFeatureToggle();
	return (
		<Styled.CustomMenu>
			<Styled.MenuHeader>
				<span>Add to...</span>
				<CloseButton onClose={props.onClose} />
			</Styled.MenuHeader>
			<Dropdown.Item onClick={props.onAddCampaign}>
				<Styled.FlexDiv alignItems='center' columnGap='1rem' data-testid='add-to-campaign-button'>
					<Styled.CustomIcon name='campaign' size='20' />
					Add to campaign
					{props.isLoadingCampaigns ? <LoadingSpinner size='sm' /> : null}
				</Styled.FlexDiv>
			</Dropdown.Item>

			{isEnabled(FEATURE_FLAG_DISCOVERY_LISTS_VIEW) && (
				<Dropdown.Item onClick={props.onAddList}>
					<Styled.FlexDiv alignItems='center' columnGap='1rem' data-testid='add-to-list-button'>
						<Styled.CustomIcon name='list-influencers' size='20' />
						Add to list
						{props.isLoadingLists ? <LoadingSpinner size='sm' /> : null}
					</Styled.FlexDiv>
				</Dropdown.Item>
			)}

			{props.children && <Dropdown.Item>{props.children}</Dropdown.Item>}
		</Styled.CustomMenu>
	);
};

export default DefaultDropdownMenuItems;
