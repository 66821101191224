import styled from 'styled-components';

import Icon from 'components/Icon';
import colors from 'styles/theme/colors';
import typography from 'styles/variables/typography';

const InfluencerNameWrapper = styled.div`
	width: 200px;

	${({ theme }) => theme.mediaQueries.xl} {
		width: 250px;
	}

	&.slide {
		& > div {
			opacity: 0;
			transform: translateY(200%);
		}
	}

	& > div {
		opacity: 1;
		transform: translateY(0);
		transition: opacity 0.1s ease-in;
		transition: transform 0.25s ease-in;
	}
`;

const InfluencerName = styled.p`
	width: 170px;
	font-weight: 600;
	font-size: 1rem;
	line-height: 1;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	margin-bottom: 0;
	padding-left: 0.5rem;
	margin-bottom: -10px;
	${({ theme }) => theme.mediaQueries.xl} {
		width: 250px;
	}
`;

const InfluencerSubText = styled.div`
	font-size: ${typography.list.small.fontSize};
	font-weight: 500;
	white-space: nowrap;

	margin-top: 8px;
	display: none;
	height: 15px;
	align-items: center;
	width: fit-content;
	border-radius: ${({ theme }) => theme.radius.small};
	padding: 0.8rem 0.5rem 0.7rem;
	&.hover-link {
		&:hover {
			background-color: ${colors.ash};
		}
	}
`;

const InfluencerSubTextWrapper = styled.div`
	${InfluencerSubText} {
		&.follower {
			display: flex;
		}
	}

	&:hover {
		${InfluencerSubText} {
			&.follower {
				display: none;
			}

			&.hover-link {
				display: flex;
			}
		}
	}
`;

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`;

const LinkToNetwork = styled.a`
	display: flex;
	text-decoration: none;
	align-items: center;
	gap: 5px;
	color: ${colors.ash};
	font-weight: 500;
	width: fit-content;

	border-bottom: none !important;

	&:hover {
		transition: revert;
		border-bottom: none !important;
	}
`;

const CustomIcon = styled(Icon)`
	&.icon {
		line-height: 48px;
	}

	&.detail-button {
		padding: 0.3125rem;
	}

	&.network-icon {
		line-height: 0.87;
		fill: ${colors.ash};
	}

	&.disabled {
		cursor: not-allowed;
		fill: ${colors.ash};

		&:hover:after {
			content: 'coming soon';
			white-space: nowrap;
			font-size: 0.5rem;
			padding: 0.25rem 0.5rem;
			position: absolute;
			top: 10px;
			left: -50%;
			z-index: 10;
		}
	}

	svg {
		transition: transform 0.1s ease-in-out;
	}
`;

const Styled = {
	InfluencerNameWrapper,
	InfluencerName,
	InfluencerSubText,
	InfluencerSubTextWrapper,
	Container,
	LinkToNetwork,
	CustomIcon,
};

export default Styled;
