import { inject, injectable } from 'inversify';

import { CampaignBudgetModel, CampaignModel } from 'api-models';
import { CreateCampaignBudgetPayload, CreateCampaignPayload, UpdateCampaignBudgetPayload, UpdateCampaignPayload } from 'api-payloads';
import {
	CreateCampaignQuery,
	ListAssignmentsQuery,
	ListCampaignsQuery,
	ListCommissionsQuery,
	ListInstagramOwnersQuery,
	ListProductsQuery,
	UpdateCampaignQuery,
	ViewCampaignQuery,
} from 'api-queries';
import {
	AssignmentCollectionResponse,
	CampaignCollectionResponse,
	CampaignCommissionCollectionResponse,
	CampaignInstagramOwnerCollectionResponse,
	CampaignProductCollectionResponse,
	CampaignResponse,
} from 'api-responses';
import ApiClientService from 'services/ApiClient/ServiceIdentifier';
import ModelRepository from 'utils/Repository/ModelRepository';
import RequestQueryBuilder from 'utils/http/RequestQueryBuilder';

import ApiCacheManager from './ApiCacheManager';
import ApiManager from './ApiManager';
import { CreateConfig, DeleteConfig, UpdateConfig, SWRConfiguration } from './types';

import type AssignmentApiClientInterface from 'services/ApiClient/AssignmentApiClientInterface';
import type CampaignApiClientInterface from 'services/ApiClient/CampaignApiClientInterface';
import type CampaignBudgetApiClientInterface from 'services/ApiClient/CampaignBudgetApiClientInterface';

type Repository = ModelRepository<CampaignModel>;

@injectable()
class CampaignManager extends ApiManager<Repository> {
	private readonly client: CampaignApiClientInterface;
	private readonly assignmentClient: AssignmentApiClientInterface;
	private readonly budgetClient: CampaignBudgetApiClientInterface;

	public constructor(
		@inject(ApiCacheManager) cacheManager: ApiCacheManager,
		@inject(ApiClientService.CampaignApiClientInterface) client: CampaignApiClientInterface,
		@inject(ApiClientService.AssignmentApiClientInterface) assignmentClient: AssignmentApiClientInterface,
		@inject(ApiClientService.CampaignBudgetApiClientInterface) budgetClient: CampaignBudgetApiClientInterface,
	) {
		super(cacheManager, new ModelRepository<CampaignModel>('campaign'));
		this.client = client;
		this.assignmentClient = assignmentClient;
		this.budgetClient = budgetClient;
	}

	public listCampaigns(queryBuilder = RequestQueryBuilder.create<ListCampaignsQuery>(), config: SWRConfiguration = {}) {
		const key = `listCampaigns::${queryBuilder.toHash()}`;
		const fetcher = () => this.client.listCampaigns(queryBuilder.toQuery());
		return this.swr<CampaignCollectionResponse>(key, fetcher, config);
	}

	public get(id: string, queryBuilder = RequestQueryBuilder.create<ViewCampaignQuery>()) {
		const key = `get::${id}_${queryBuilder.toHash()}`;
		const fetcher = () => this.client.view(id, queryBuilder.toQuery());

		return this.swr<CampaignResponse>(key, fetcher, {
			revalidateOnFocus: false,
			revalidateIfStale: false,
		});
	}

	public create(
		payload: CreateCampaignPayload,
		queryBuilder = RequestQueryBuilder.create<CreateCampaignQuery>(),
		config: CreateConfig<CampaignModel> = {},
	): Promise<CampaignModel> {
		return this.createModel<CampaignModel>(this.client.create(payload, queryBuilder.toQuery()), config);
	}

	public update(
		id: string,
		payload: UpdateCampaignPayload,
		queryBuilder = RequestQueryBuilder.create<UpdateCampaignQuery>(),
		config: UpdateConfig<CampaignModel> = {},
	): Promise<CampaignModel> {
		return this.updateModel<CampaignModel>(this.client.update(id, payload, queryBuilder.toQuery()), config);
	}

	public delete(id: string, config: DeleteConfig = {}): Promise<Repository> {
		return this.deleteModel(this.client.delete(id), 'campaign', id, config);
	}

	public deleteAssignment(campaignId: string, assignmentId: string): Promise<void> {
		return this.assignmentClient.delete(campaignId, assignmentId).then(() => {
			return this.refetch('campaign', campaignId);
		});
	}

	public deleteCommission(campaignId: string, assignmentId: string): Promise<void> {
		return this.client.deleteCommission(campaignId, assignmentId).then(() => {
			return this.refetch('campaign', campaignId);
		});
	}

	public deleteProduct(campaignId: string, assignmentId: string): Promise<void> {
		return this.client.deleteProduct(campaignId, assignmentId).then(() => {
			return this.refetch('campaign', campaignId);
		});
	}

	public archive(id: string): Promise<void> {
		return this.client.setAsArchived(id, { include: ':hateoas(false)' }).then(() => {
			return this.refetch('campaign', id);
		});
	}

	public listProducts(campaignId: string, queryBuilder = RequestQueryBuilder.create<ListProductsQuery>()) {
		const key = `listProducts::${campaignId}_${queryBuilder.toHash()}`;
		const fetcher = () => this.client.listProducts(campaignId, queryBuilder.toQuery());

		return this.swr<CampaignProductCollectionResponse>(key, fetcher);
	}

	public listCommission(campaignId: string, queryBuilder = RequestQueryBuilder.create<ListCommissionsQuery>()) {
		const key = `listCommission::${campaignId}_${queryBuilder.toHash()}`;
		const fetcher = () => this.client.listCommissions(campaignId, queryBuilder.toQuery());

		return this.swr<CampaignCommissionCollectionResponse>(key, fetcher, {
			revalidateOnFocus: false,
			revalidateIfStale: false,
		});
	}

	public listAssignments(campaignId: string, queryBuilder = RequestQueryBuilder.create<ListAssignmentsQuery>()) {
		queryBuilder = queryBuilder.withFilter('campaigns', campaignId);

		const key = `listAssignments::${campaignId}_${queryBuilder.toHash()}`;
		const fetcher = () => this.assignmentClient.listAssignments(queryBuilder.toQuery());

		return this.swr<AssignmentCollectionResponse>(key, fetcher, {
			revalidateOnFocus: false,
			revalidateIfStale: false,
		});
	}

	public listInfluencers(campaignId: string, queryBuilder = RequestQueryBuilder.create<ListInstagramOwnersQuery>()) {
		const key = `listInfluencers::${campaignId}_${queryBuilder.toHash()}`;
		const fetcher = () => this.client.listInstagramOwners(campaignId, queryBuilder.toQuery());

		return this.swr<CampaignInstagramOwnerCollectionResponse>(key, fetcher, {
			revalidateOnFocus: false,
			revalidateIfStale: false,
		});
	}

	public createBudget(campaignId: string, payload: CreateCampaignBudgetPayload, config: CreateConfig<CampaignBudgetModel> = {}) {
		return this.createModel<CampaignBudgetModel>(this.budgetClient.create(campaignId, payload, { include: ':hateoas(false)' }), config).then(() => {
			return this.refetch('campaign', campaignId);
		});
	}

	public updateBudget(budgetId: string, payload: UpdateCampaignBudgetPayload, config: UpdateConfig<CampaignBudgetModel> = {}) {
		return this.updateModel<CampaignBudgetModel>(this.budgetClient.update(budgetId, payload, { include: ':hateoas(false)' }), config);
	}

	public deleteBudget(campaignId: string, budgetId: string): Promise<void> {
		return this.budgetClient.delete(budgetId).then(() => {
			return this.refetch('campaign', campaignId);
		});
	}
}

export default CampaignManager;
