import styled from 'styled-components';

import colors from 'styles/theme/colors';

const Back = styled.div`
	cursor: pointer;
	width: fit-content;
	white-space: nowrap;
	font-size: 0.875rem;
	font-weight: 600;
	display: flex;
	align-items: center;
	color: ${colors.black} !important;
	&:active,
	&:visited {
		color: ${colors.black} !important;
	}
	.icon {
		margin-right: 8px;
	}
`;

const Styled = {
	Back,
};

export default Styled;
