import React from 'react';
import { RouteObject } from 'react-router-dom';

import PublicDashboard from 'components/DataLibrary/PublicDashboard';
import PageNotFound from 'components/ErrorPages/PageNotFound';
import LoadingSpinner from 'components/LoadingSpinner';
import OrganizationLayout from 'layouts/organization';
import DataLibrary from 'views/DataLibrary';

import { ProtectedRoute } from './layout-routes/ProtectedRoute';
/**
 * Data Library Routes
 * Routes for Data Library
 * @returns {Array<RouteObject>}
 */

const routes: Array<RouteObject> = [
	{
		path: '',
		element: (
			<React.Suspense fallback={<LoadingSpinner />}>
				<OrganizationLayout />
			</React.Suspense>
		),
		children: [
			{
				index: true,
				element: (
					<ProtectedRoute>
						<DataLibrary />
					</ProtectedRoute>
				),
			},
			{
				path: ':dashboardId?',
				element: (
					<ProtectedRoute>
						<DataLibrary />
					</ProtectedRoute>
				),
			},
			{ path: ':dashboardId/preview', element: <PublicDashboard /> },
			{
				path: 'dashboard/:dashboardId?',
				element: (
					<ProtectedRoute>
						<DataLibrary />
					</ProtectedRoute>
				),
			},
			{ path: '*', element: <PageNotFound /> },
		],
	},
];

export default routes;
