import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import boxShadow from 'styles/variables/box-shadows';

const ExamplesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-top: 3rem;
`;

const Text = styled.div`
	width: 50%;
	margin: auto;
	text-align: center;
`;

const CardContainer = styled.div`
	width: 100%;
	overflow-x: auto;
	display: flex;
	gap: 16px;
	margin-top: 40px;
`;

const Card = styled.div<{ img: string }>`
	min-width: 230px;
	height: 305px;
	border-radius: ${borderRadius.m};
	background-color: ${newColors.mist};
	position: relative;
	background: url(${(props) => props.img ?? ''});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	align-items: flex-end;
	transition: all 0.5s;
`;

const CardWrapper = styled.div`
	position: relative;
	flex: 1;
	overflow: hidden;
	border-radius: ${({ theme }) => theme.radius.medium};
	min-width: 230px;
	height: 305px;
	&:hover {
		box-shadow: ${boxShadow.medium};
		${Card} {
			cursor: pointer;
			transform: scale(1.2);
		}
	}
`;

const SearchText = styled.div`
	display: flex;
	height: 48px;
	border-radius: ${borderRadius.s};
	color: ${newColors.smoke};
	padding: 1rem;
	width: calc(100% - 16px);
	flex-grow: 1;
	background-color: #f2f2f2b9;
	position: absolute;
	bottom: 8px;
	left: 8px;
	right: 8px;
	span {
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 0.875rem;
		font-weight: 600;
		line-height: 1.3;
	}
`;

const Styled = {
	Card,
	CardWrapper,
	CardContainer,
	SearchText,
	Text,
	ExamplesWrapper,
};

export default Styled;
