import classNames from 'classnames';
import { Store } from 'json-api-models';
import { uniq } from 'lodash';
import { useEffect, useState, useRef, useContext } from 'react';

import { DarkButton, GhostButton } from 'components/Button';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner';
import PageHeader from 'components/PageHeader';
import Pill from 'components/Pill';
import { SideDrawer } from 'components/SideDrawer';
import Tabs from 'components/Tabs/Tabs';
import Tooltip from 'components/Tooltip/V2';
import { Network } from 'constants/socialMedia';
import DiscoveryContext from 'contexts/Discovery';
import { InfluencerListItemType } from 'contexts/Discovery/types';
import { getSomethingWentWrongMessage } from 'hooks/ToastPortal/toastMessages';
import DiscoveryAuthService from 'services/Authentication/Discovery-api';
import DiscoveryService from 'services/DiscoveryApi';
import { StatusCode } from 'services/Response.types';
import toast from 'services/Toast';
import MainContent from 'styles/layout/main-content';

import AutoCompleteList from './Components/AutoCompleteList';
import { AutoCompleteOption } from './Components/AutoCompleteList/AutoCompleteList';
import DiscoveryDropdown from './Components/DiscoveryDropdown';
import FilterDrawer from './Components/FilterDrawer';
import IconButton from './Components/IconButton';
import MessageModal from './Components/MessageModal';
import ResultContainer from './Components/ResultContainer';
import SearchExamples from './Components/SearchExamples/SearchExamples';
import SearchInput from './Components/SearchInput';
import Styled from './DiscoveryContainer.style';
import { extractUsernameAndNetwork, mapAutoCompleteOptions } from './helpers';

/**
 */
const DiscoveryContainer = () => {
	const { loading, isLoadingNext, searchText, searchResult, filter, page, isMessageModalOpen, selectedNetwork } = useContext(DiscoveryContext);

	const changeSearchValueHandler = useContext(DiscoveryContext).changeSearchValueHandler;
	const resetFilter = useContext(DiscoveryContext).resetFilter;
	const setFilter = useContext(DiscoveryContext).setFilter;
	const setPage = useContext(DiscoveryContext).setPage;
	const updateNetworkHandler = useContext(DiscoveryContext).updateNetworkHandler;
	const closeMessageModal = useContext(DiscoveryContext).closeMessageModal;

	const [cursor, setCursor] = useState(-1);
	const [inputValue, setInputValue] = useState<string | null>(null);
	const [debouncedInputValue, setDebouncedInputValue] = useState('');
	const [autoCompleteOptions, setAutoCompleteOptions] = useState<AutoCompleteOption[]>([]);
	const [didYouMeanOptions, setDidYouMeanOptions] = useState<AutoCompleteOption[]>([]);
	const [fuzzySearchOptions, setFuzzySearchOptions] = useState<AutoCompleteOption[]>([]);

	const [showFilter, setShowFilter] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const [isFetchingDropdownItems, setIsFetchingDropdownItems] = useState(false);

	const [selectedInfluencers, setSelectedInfluencers] = useState<string[]>([]);
	const [selectedInfluencersResult, setSelectedInfluencersResult] = useState<InfluencerListItemType[]>([]);
	const [showSelectedInfluencer, setShowSelectedInfluencers] = useState<boolean>(false);

	const CancelSignal = new AbortController();
	const cancel = useRef<AbortController | null>(null);
	const cursoredItem = useRef<{ text: string }>({ text: '' });
	const models = new Store();

	useEffect(() => {
		setInputValue(searchText ?? null);
	}, [searchText]);

	const fetchAutoCompleteData = async () => {
		if (!inputValue || inputValue.length < 2 || !isSearching) {
			setAutoCompleteOptions([]);
			setFuzzySearchOptions([]);
			return;
		}
		setIsFetchingDropdownItems(true);
		cancel.current = CancelSignal;
		try {
			const [autoCompleteRes, fuzzySearchRes] = await Promise.all([
				DiscoveryService.autoCompleteSearch(inputValue, cancel.current.signal),
				DiscoveryService.fuzzySearch(inputValue, cancel.current.signal),
			]);

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const handleResponse = (res: any, setOptions: (options: AutoCompleteOption[]) => void) => {
				models.sync(res);
				const newOptions = mapAutoCompleteOptions(res.data);
				setOptions(newOptions);
				setDidYouMeanOptions(newOptions);
				setDebouncedInputValue(inputValue);
			};

			handleResponse(autoCompleteRes, setAutoCompleteOptions);
			handleResponse(fuzzySearchRes, setFuzzySearchOptions);

			cancel.current = null;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			if (err.response) {
				if (err.response.status === StatusCode.UNAUTHORIZED) {
					DiscoveryAuthService.requestDiscoveryToken();
				}
				const error = err.response.data.errors?.[0];
				toast.error(error ? error.title : getSomethingWentWrongMessage());
			}
		} finally {
			setIsFetchingDropdownItems(false);
		}
	};

	const keyboardNavigation = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'ArrowDown') {
			e.preventDefault();
			if (autoCompleteOptions.length > 0) {
				setCursor((prev) => {
					return prev < autoCompleteOptions.length - 1 ? prev + 1 : prev;
				});
			}
		}
		if (e.key === 'ArrowUp') {
			e.preventDefault();
			if (autoCompleteOptions.length > 0) {
				setCursor((prev) => {
					return prev > 0 ? prev - 1 : 0;
				});
			}
		}
		if (e.key === 'Escape') {
			setCursor(-1);
			setAutoCompleteOptions([]);
		}
		if (e.key === 'Enter') {
			if (cursor !== -1) {
				changeSearchValueHandler({ text: cursoredItem.current.text });
				setInputValue(cursoredItem.current.text);
			} else {
				changeSearchValueHandler({ text: inputValue });
				setIsSearching(false);
			}
		}
	};

	const changeHandler = (searchWord: string) => {
		if (inputValue !== searchWord) {
			const { network, username } = extractUsernameAndNetwork(searchWord);

			if (username && network) {
				// If a valid URL is pasted, update the network and search value
				setInputValue(`@${username}`);
				updateNetworkHandler(network);
				setFilter({ ...filter, networks: network });
				changeSearchValueHandler({ text: `@${username}` });
			} else {
				// Otherwise, treat it as a regular search
				setInputValue(searchWord);
				setIsSearching(true);
			}
		}
	};

	// Update this with network and add later, new issue
	// const cursorOptionHandler = (id: string, label: string) => {
	// 	cursoredItem.current = { text: `@${label}` };
	// 	setIsSearching(false);
	// };

	const selectAllHandler = () => {
		if (searchResult) {
			if (selectedInfluencers.length === searchResult.length) {
				setSelectedInfluencers([]);
			} else {
				setSelectedInfluencers(searchResult.map((influencer: InfluencerListItemType) => influencer.id));
			}
		}
	};

	const selectOneHandler = () => {
		return (id: string) => {
			setSelectedInfluencers((prev) => {
				const isExist = prev.some((prevId) => prevId === id);
				if (isExist) {
					return prev.filter((prevId) => prevId !== id);
				} else {
					return prev.concat(id);
				}
			});
		};
	};

	useEffect(() => {
		setCursor(-1);
		if (inputValue && inputValue.length === 0) {
			setAutoCompleteOptions([]);
		}
		if (cancel.current !== null) {
			cancel.current.abort();
			cancel.current = null;
		}
		if (inputValue && inputValue.length >= 2) {
			const timerId = setTimeout(() => {
				fetchAutoCompleteData();
			}, 300);
			return () => clearTimeout(timerId);
		}
	}, [inputValue]);

	const loadMore = () => {
		const pageAsString = parseInt(page || '') + 1;
		setPage(pageAsString.toString());
	};

	const filterCount = filter !== null ? Object.entries(filter).filter(([key, value]) => value !== null && value !== '' && key !== 'networks').length : 0;

	useEffect(() => {
		const influencersInSearchResult = () => {
			const total = uniq(searchResult?.filter((result) => selectedInfluencers?.includes(result.id))) || [];
			return total;
		};

		const influencersInSavedSearchResult = () => {
			const total = uniq(selectedInfluencersResult?.filter((result) => selectedInfluencers?.includes(result.id))) || [];
			return total;
		};

		const total = influencersInSearchResult().concat(influencersInSavedSearchResult());
		const searchResultEmpty = influencersInSearchResult().length === 0;
		const savedSearchResultEmpty = influencersInSavedSearchResult().length === 0;

		if (!searchResultEmpty && !savedSearchResultEmpty) {
			setSelectedInfluencersResult(uniq(total));
		} else if (searchResultEmpty) {
			setSelectedInfluencersResult(influencersInSavedSearchResult());
		} else if (savedSearchResultEmpty) {
			setSelectedInfluencersResult(influencersInSearchResult());
		}
	}, [selectedInfluencers]);

	useEffect(() => {
		setShowSelectedInfluencers(false);
	}, [searchResult]);

	const clearSearch = () => {
		changeSearchValueHandler({
			text: '',
		});
		setInputValue('');
		setIsSearching(false);
		setAutoCompleteOptions([]);
		setFuzzySearchOptions([]);
	};

	const handleSelectOption = (id: string, label: string, network: Network) => {
		setInputValue(`@${label}`);
		changeSearchValueHandler({ text: `@${label}` });
		setIsSearching(false);
		setFilter({ ...filter, networks: network });
		updateNetworkHandler(network);
	};

	const renderData = () => {
		return showSelectedInfluencer ? selectedInfluencersResult : searchResult ?? [];
	};

	return (
		<Styled.Wrapper>
			<Styled.Header>
				<PageHeader headline='Discovery'></PageHeader>
				<MainContent>
					<Styled.SearchArea>
						<Styled.TabsWrapper>
							<Tabs
								className='on-discovery'
								tabs={[
									{ title: Network.INSTAGRAM, icon: 'instagram' },
									{ title: Network.TIKTOK, icon: 'tiktok' },
								]}
								selectedTab={selectedNetwork}
								setSelectedTab={(tab) => updateNetworkHandler(tab as Network)}
							/>
						</Styled.TabsWrapper>
						<Styled.SearchInputSection>
							<Styled.SearchInputWrapper>
								<SearchInput
									value={inputValue ? inputValue : ''}
									onChange={(e) => {
										changeHandler(e.target.value);
										if (e.target.value.length === 0) {
											clearSearch();
										}
									}}
									onKeyDown={(e) => {
										keyboardNavigation(e);
									}}
									onReset={() => {
										changeHandler('');
										clearSearch();
									}}
									placeholder='Search keyword or profile with @'
								/>
								<Styled.IconContainer>
									<IconButton
										iconName='filters'
										onClick={() => {
											setShowFilter((prev) => !prev);
										}}
									/>
								</Styled.IconContainer>
							</Styled.SearchInputWrapper>
							{isSearching && (
								<AutoCompleteList
									inputValue={debouncedInputValue}
									cursor={cursor}
									options={autoCompleteOptions}
									isFetchingDropdownItems={isFetchingDropdownItems}
									fuzzySearchOptions={fuzzySearchOptions}
									onSelect={handleSelectOption}
									// Creating a new issue for this.
									// onCursor={(id, label) => {
									// 	cursorOptionHandler(id, label);
									// }}
									onClickOutside={() => {
										setIsSearching(false);
									}}
								/>
							)}
						</Styled.SearchInputSection>
						<Styled.FilterButtonAndTag>
							<Styled.ClearFilterButtonContainer>
								<Styled.CustomButton
									onClick={() => {
										setShowFilter((prev) => !prev);
									}}
									className={classNames('filters', { 'filter-opened': showFilter })}
								>
									<Styled.CustomIcon name='filters' size='20' />
									Filter {filterCount > 0 ? <Pill className='filter' title={`${filterCount}`} /> : null}
								</Styled.CustomButton>
								{filterCount > 0 ? (
									<Styled.FilterSummaryWrapper>
										<Styled.ClearFilterButton onClick={resetFilter}>Clear filters</Styled.ClearFilterButton>
									</Styled.FilterSummaryWrapper>
								) : null}
							</Styled.ClearFilterButtonContainer>
						</Styled.FilterButtonAndTag>
					</Styled.SearchArea>

					<Styled.ButtonsWrapper>
						{selectedInfluencers.length > 0 && (
							<Styled.FlexDiv>
								<Tooltip content='Add to list/campaign' delayShow={300}>
									<DiscoveryDropdown selectedItems={selectedInfluencers} onResetSelection={() => setSelectedInfluencers([])} size='16' />
								</Tooltip>
							</Styled.FlexDiv>
						)}
						{selectedInfluencers.length > 0 && (
							<>
								<Styled.Divider />
								<Styled.SelectedInfluencerContainer onClick={() => setShowSelectedInfluencers(!showSelectedInfluencer)}>
									<Styled.SelectedInfluencer>
										{showSelectedInfluencer ? `Continue search` : `Show ${selectedInfluencers.length} selected`}
									</Styled.SelectedInfluencer>
									<Icon name='chevron-down' size='16' />
								</Styled.SelectedInfluencerContainer>
							</>
						)}
					</Styled.ButtonsWrapper>
				</MainContent>
			</Styled.Header>
			<MainContent>
				{showSelectedInfluencer || searchText ? (
					<>
						{searchResult && searchText && (
							<Styled.ResultText>
								Showing results for <strong>{searchText}</strong>
							</Styled.ResultText>
						)}
						<Styled.ResultContentWrapper>
							{loading ? (
								<LoadingSpinner position='center' />
							) : (
								<ResultContainer
									setShowSelectedInfluencers={setShowSelectedInfluencers}
									isLoadingNext={isLoadingNext}
									loadMore={loadMore}
									isInfluencerSearch={inputValue && inputValue.startsWith('@') ? true : false}
									tableList={renderData()}
									selectedInfluencers={selectedInfluencers}
									onSelectAll={selectAllHandler}
									onSelectOne={selectOneHandler}
									handleSelectOption={handleSelectOption}
									clearSearch={clearSearch}
									selectedNetwork={selectedNetwork}
									fuzzySearchOptions={didYouMeanOptions}
								/>
							)}
							<SideDrawer
								sidebarIsOpen={showFilter}
								onClose={() => setShowFilter(false)}
								dataTestId={'discovery-filters'}
								title={`All Filters (${filterCount})`}
								isExpandable
								customButtons={
									<>
										<GhostButton size='sm' onClick={resetFilter}>
											Clear all filter
										</GhostButton>
										<DarkButton onClick={() => setShowFilter(false)} size='sm'>
											View results
										</DarkButton>
									</>
								}
							>
								<FilterDrawer />
							</SideDrawer>
						</Styled.ResultContentWrapper>
					</>
				) : (
					<SearchExamples />
				)}
			</MainContent>
			<MessageModal open={isMessageModalOpen} onClose={closeMessageModal} />
		</Styled.Wrapper>
	);
};

export default DiscoveryContainer;
