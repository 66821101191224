import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import { scrollbarY } from 'styles/utils/scrollbar';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import { devices } from 'styles/variables/media-queries';
import { spacing } from 'styles/variables/spacings';

const SideBarWrapper = styled.div`
	display: none;
	&.show {
		display: block;
		background-color: ${colors.AffiliateMarketplace.rightContainerBackground};
		backdrop-filter: blur(2px);
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: ${({ theme }) => theme.zIndexes.sidedrawer};
	}
`;

const InnerWrapper = styled.div`
	display: none;

	&.show {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		animation: slideIn 0.2s forwards;
		box-shadow: 0px 10px 20px 0px rgba(92, 104, 128, 0.16);
	}

	@keyframes slideIn {
		from {
			transform: translateX(-100%);
			display: flex;
		}
		to {
			transform: translateX(0);
			display: flex;
		}
	}
	@media screen and (${devices.md}) {
		@keyframes slideIn {
			from {
				transform: translateX(100%);
				display: flex;
			}
			to {
				transform: translateX(0);
				display: flex;
			}
		}
	}
`;

const DetailDrawer = styled.div`
	width: 100vw;
	height: 100vh;
	background-color: ${colors.CampaignDetailSidebar.background};
	display: flex;
	flex-direction: column;
	${scrollbarY};
	@media screen and (${devices.md}) {
		border-radius: ${borderRadius.m} 0px 0px ${borderRadius.m};
		width: 423px;
		transition: width 0.2s ease-in;
		&.drawerIsExpanded {
			width: 1256px;
		}
	}
`;

const DrawerBody = styled.div`
	overflow-x: hidden;
	height: calc(100vh - 48px);
	padding: ${spacing[4].rem};
`;

const CloseAction = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	color: ${colors.CampaignDetailSidebar.closeText};
	transition: color ease-in-out 0.2s;
	font-size: 1rem;
	gap: 8px;
	margin-top: 7px;
	.icon {
		margin-top: 4px;
		path {
			fill: ${colors.mainBlack};
		}
	}

	& > span {
		margin-top: 4px;
		&.icon-wrapper {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: background-color ease-in-out 0.2s;
		}
	}

	&:hover {
		color: ${colors.CampaignDetailSidebar.hoverCloseText};
		& > span {
			&.icon-wrapper {
				background-color: ${newColors.ash};
			}
		}
	}
`;

const DrawerHeader = styled.div`
	width: 100%;
	padding: 2rem ${spacing[4].rem};
	height: 82px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid ${colors.gray9};
	& > div {
		&.drawer-title {
			margin-top: 7px;
			font-weight: 600;
			font-size: 1rem;
			color: ${colors.mainBlack};
		}
	}
`;

const ExpandableBody = styled.div`
	display: flex;
	position: relative;
	height: 100%;
	width: 100%;
	${scrollbarY};
`;

const Styled = {
	SideBarWrapper,
	DrawerHeader,
	DrawerBody,
	DetailDrawer,
	InnerWrapper,
	CloseAction,
	ExpandableBody,
};

export default Styled;
