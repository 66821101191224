import toast from 'services/Toast';

export const DROPDOWN_OPTION = {
	NULL: '',
	OPEN_SUB_FOLDER: 'folder',
	ADD_TO_LIST: 'list',
	ADD_TO_CAMPAIGN: 'campaign',
};

export const handleSuccessToast = (influencers: string[], targetName: string | undefined, itemId: string, selectedOption: string) => {
	const number = influencers.length <= 3 ? ['One', 'Two', 'Three'][influencers.length - 1] || `${influencers.length}` : `${influencers.length}`;
	const url = selectedOption === DROPDOWN_OPTION.ADD_TO_LIST ? `/influencer-management/lists/${itemId}` : `/campaigns/${itemId}`;
	toast.successWithLink(`${number} influencer${influencers.length > 1 ? 's' : ''} added to`, `${targetName}`, `${url}`);
};

export const scrollToItem = (id: string) => {
	id &&
		document.getElementById(id)?.scrollIntoView({
			block: 'center',
			behavior: 'smooth',
		});
};
