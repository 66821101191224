import classNames from 'classnames';
import { useState, useEffect, useContext, useCallback } from 'react';

import Checkbox from 'components/Checkbox';
import DiscoveryDropdown from 'components/Discovery/Components/DiscoveryDropdown';
import IconButton from 'components/Discovery/Components/IconButton';
import InfluencerAvatarWithFollowers from 'components/Discovery/Components/InfluencerAvatarWithFollowers/InfluencerAvatarWithFollowers';
import InfluencerDetailCard from 'components/Discovery/Components/InfluencerDetailCard/';
import InfluencerDetailCardDiscovery from 'components/Discovery/Components/InfluencerDetailCardDiscovery';
import { SideDrawer } from 'components/SideDrawer';
import { DELETE_INFLUENCER_FROM_LIST } from 'constants/hateoas-keys';
import DiscoveryContext from 'contexts/Discovery';
import { InfluencerExtraData, InfluencerListItemType } from 'contexts/Discovery/types';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { ICollabsResponse } from 'services/Response.types';
import { formatNumberWithDecimals } from 'shared/helpers/Chart/chart-util';

import InfluencerListItemComments from './Components/Comments/InfluencerListItemComments';
import InfluencerListItemReactions from './Components/Comments/InfluencerListItemReactions';
import ExpandedTitle from './Components/ExpandedTitle/ExpandedTitle';
import WhyDoIGetThisResult from './Components/WhyDoIGetThisResult/WhyDoIGetThisResult';
import Styled from './InfluencerListItem.style';
import { defaultData, getNameAndRatio } from './helpers';
import { InfluencerListItemProps } from './types';

const InfluencerListItem = ({
	onSelect,
	data,
	isSelected,
	isList = false,
	onFetchListInflencerExtra,
	onDeleteFromList,
	listItem,
	nextPageRef,
	isOdd,
	whyDoIGetLink,
	dataTestId,
}: InfluencerListItemProps) => {
	const [influencerData, setInfluencerData] = useState<InfluencerListItemType>(defaultData);
	const [influencerExtraData, setInfluencerExtraData] = useState<InfluencerExtraData | null>(null);
	const [showDetail, setShowDetail] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);
	const [isHovered, setIsHovered] = useState(false); // Track hover state

	const { userCan } = useFeaturePermissions({ ...(isList ? data.listItem.links : {}), ...data.links });

	const getOneInfluencerExtraData = useContext(DiscoveryContext).getOneInfluencerExtraData;
	const openMessageModal = useContext(DiscoveryContext).openMessageModal;

	const getInfluencerDetail = useCallback(
		(id: string) => {
			getOneInfluencerExtraData(id).then((res) => {
				setInfluencerData(res);
			});
		},
		[getOneInfluencerExtraData],
	);

	const fetchInfluencerExtraDataHandler = useCallback(() => {
		if (!showDetail && onFetchListInflencerExtra) {
			onFetchListInflencerExtra(data.id).then((res) => {
				setInfluencerExtraData(res || null);
			});
		}
		setShowDetail((prev) => !prev);
	}, [showDetail, onFetchListInflencerExtra, data.id]);

	useEffect(() => {
		if (influencerExtraData) {
			setInfluencerData((prev) => ({ ...prev, ...influencerExtraData }));
		}
	}, [influencerExtraData]);

	useEffect(() => {
		if (data) {
			setInfluencerData((prev) => ({ ...prev, ...data }));
		}
	}, [data]);

	useEffect(() => {
		if (!showDetail) {
			setIsExpanded(false);
		}
	}, [showDetail]);

	useEffect(() => {
		if (showDetail) {
			document.body.style.overflow = 'hidden'; // Disable scrolling on the body
		} else {
			setIsExpanded(false);
			document.body.style.overflow = ''; // Restore scroll behavior
		}
		return () => {
			document.body.style.overflow = ''; // Clean up on component unmount or showDetail change
		};
	}, [showDetail]);

	const open = useCallback(() => {
		openMessageModal(influencerData);
	}, [openMessageModal, influencerData]);

	const getReason = useContext(DiscoveryContext).getReason;
	const [loading, setLoading] = useState(false);
	const [reasons, setReasons] = useState<ICollabsResponse | null>(null);

	const showReasonHandler = useCallback(
		(url: string) => {
			setLoading(true);
			getReason(url).then((res: ICollabsResponse) => {
				setReasons(res || null);
				setLoading(false);
			});
		},
		[getReason],
	);

	useEffect(() => {
		if (showDetail) {
			const focusElement = document.getElementById(`focus-${data.id}`); // An element elsewhere
			if (focusElement) focusElement.focus();
		}
	}, [showDetail]);

	return (
		influencerData && (
			<>
				<Styled.Wrapper
					id={`focus-${data.id}`}
					data-testid={dataTestId}
					ref={nextPageRef}
					className={classNames({ odd: isOdd && !showDetail, isSelected: isSelected })}
					onClick={
						isList
							? fetchInfluencerExtraDataHandler
							: (e) => {
									e.preventDefault();
									getInfluencerDetail(data.id);
									setShowDetail((prev) => !prev);
								}
					}
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
				>
					<Styled.Td>
						<Checkbox
							checked={isSelected}
							onChange={(e) => {
								e.stopPropagation();
								onSelect();
							}}
						/>
					</Styled.Td>
					<Styled.Td maxWidth='250px'>
						<InfluencerAvatarWithFollowers influencerData={influencerData} data={data} />
					</Styled.Td>
					<Styled.Td>
						<Styled.Data>{influencerData.engagement !== null ? formatNumberWithDecimals(influencerData.engagement, '%') : '-'}</Styled.Data>
					</Styled.Td>
					<Styled.Td>{influencerData.audienceBrief.age.name ? <Styled.Data>{getNameAndRatio(influencerData.audienceBrief.age)}</Styled.Data> : `-`}</Styled.Td>
					<Styled.Td maxWidth='100px'>
						{influencerData.audienceBrief.country.name ? (
							<Styled.Data className='location'>{getNameAndRatio(influencerData.audienceBrief.country)}</Styled.Data>
						) : (
							`-`
						)}
					</Styled.Td>
					<Styled.Td>
						{influencerData.audienceBrief.gender.name ? <Styled.Data>{getNameAndRatio(influencerData.audienceBrief.gender)}</Styled.Data> : `-`}
					</Styled.Td>
					<Styled.Td text-align='right'>
						<Styled.ReactionsContainer>
							<div className={`opacity-${isHovered ? '1' : '0'}`}>
								<IconButton onClick={(e) => e.stopPropagation()}>
									<Styled.IconContainer className='dropdown' data-testid='add-to-icon'>
										<DiscoveryDropdown helpText='Add to list/campaign' selectedItems={[influencerData.id]} />
									</Styled.IconContainer>
								</IconButton>
								<IconButton
									onClick={(e) => {
										e.stopPropagation();
										open();
									}}
									className='influencer-list-item'
									iconName='private-message'
									helpText='Private message'
								/>
							</div>
							{isList && listItem && (
								<>
									<InfluencerListItemComments listItem={listItem} />
									<InfluencerListItemReactions listItem={listItem} />
								</>
							)}
							{isList && userCan(DELETE_INFLUENCER_FROM_LIST) && onDeleteFromList && (
								<IconButton
									onClick={(e) => {
										e.stopPropagation();
										onDeleteFromList();
									}}
									className='influencer-list-item'
									iconName='trash-bin'
									testId={`list-influencer-delete-${data.id}`}
									helpText='Remove'
								/>
							)}
						</Styled.ReactionsContainer>
					</Styled.Td>
				</Styled.Wrapper>
				{showDetail && isList ? (
					<Styled.ExpendWrapper>
						<Styled.Td colSpan={100}>
							<Styled.ExpendInnrWrapper>
								<InfluencerDetailCard
									whyDoIGetLink={whyDoIGetLink}
									influencer={influencerData}
									onFetchInfluencerData={(id: string) => {
										getInfluencerDetail(id);
									}}
									onSelect={() => onSelect()}
									isSelected={isSelected}
								/>
							</Styled.ExpendInnrWrapper>
						</Styled.Td>
					</Styled.ExpendWrapper>
				) : (
					showDetail && (
						<SideDrawer
							sidebarIsOpen={showDetail}
							onClose={() => {
								setShowDetail(!showDetail), setIsExpanded(false);
							}}
							dataTestId={''}
							title='Influencer stats'
							isExpandable
							isExpanded={isExpanded}
							onDone={() => setIsExpanded(false)}
							setNotExpanded={() => setIsExpanded(false)}
							expandedContent={<WhyDoIGetThisResult isLoading={loading} reasons={reasons} influencerData={influencerData} data={data} />}
							canOnlyCloseModal
							expandedTitle={<ExpandedTitle influencerData={influencerData} data={data} onSelect={onSelect} isSelected={isSelected} />}
						>
							<InfluencerDetailCardDiscovery
								whyDoIGetLink={whyDoIGetLink}
								influencer={influencerData}
								onFetchInfluencerData={(id: string) => {
									getInfluencerDetail(id);
								}}
								setIsExpanded={(isExpanded) => setIsExpanded(isExpanded)}
								isExpanded={isExpanded}
								showReasonHandler={showReasonHandler}
							/>
						</SideDrawer>
					)
				)}
			</>
		)
	);
};

export default InfluencerListItem;
