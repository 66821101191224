export const getGender = (gender: string) => {
	switch (gender) {
		case 'female':
			return 'f';
		case 'male':
			return 'm';
		default:
			return '';
	}
};
