import React, { memo } from 'react';

import InfluencerAvatar from 'components/InfluencerAvatar';
import { Network } from 'constants/socialMedia';
import { InfluencerListItemType } from 'contexts/Discovery/types';
import { formatNumber } from 'shared/helpers/Chart/chart-util';

import Styled from './InfluencerAvatarWithFollowers.style';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InfluencerAvatarWithFollowers = memo((props: { influencerData: InfluencerListItemType; data: any }) => {
	const renderGoToNetwork = () => {
		const { network, networkLinks } = props.influencerData;
		const networkUrl = networkLinks[network as keyof typeof networkLinks];
		if (!networkUrl) return null;

		return (
			<Styled.LinkToNetwork href={networkUrl} target='_blank'>
				<Styled.CustomIcon name={network} size='14' className='network-icon' />
				{`Go to ${network}`}
			</Styled.LinkToNetwork>
		);
	};

	return (
		<Styled.Container>
			<InfluencerAvatar
				size='lg'
				userName={props.influencerData.username}
				network={props.influencerData.network as Network}
				displayNetwork
				profileImageUrl={props.data.profileImageUrl ?? ''}
			/>
			<Styled.InfluencerNameWrapper>
				<Styled.InfluencerName>{props.influencerData.username}</Styled.InfluencerName>
				<Styled.InfluencerSubTextWrapper>
					<Styled.InfluencerSubText className='hover-link'>{renderGoToNetwork()}</Styled.InfluencerSubText>
					<Styled.InfluencerSubText className='follower'>
						<div>{props.influencerData.followersCount && formatNumber(props.influencerData.followersCount)} Followers</div>
					</Styled.InfluencerSubText>
				</Styled.InfluencerSubTextWrapper>
			</Styled.InfluencerNameWrapper>
		</Styled.Container>
	);
});

export default InfluencerAvatarWithFollowers;
