import classNames from 'classnames';
import React, { useContext } from 'react';

import noResult from 'assets/icons/empty-state-no-result.svg';
import Checkbox from 'components/Checkbox';
import InfluencerListItem from 'components/Discovery/Components/InfluencerListItem';
import EmptyState from 'components/EmptyState/V2';
import LoadingSpinner from 'components/LoadingSpinner';
import DiscoveryContext from 'contexts/Discovery';

import Styled from './ResultTable.style';
import { Props, TABLE_FIELDS } from './types';

const ResultTable = ({ list, selectedItems, onSelectAll, onSelectOne, loadMore, isLoadingNext, setShowSelectedInfluencers }: Props) => {
	const isSelected = selectedItems.length > 0;

	const { loading } = useContext(DiscoveryContext);

	if (list.length === 0) {
		return (
			<Styled.EmptyResultWrapper>
				<EmptyState image={noResult} altText='No result found' testId='no-result-found' text={<p>No results to display.</p>} />
			</Styled.EmptyResultWrapper>
		);
	}

	return (
		<>
			<Styled.Table cellSpacing={0}>
				<Styled.TableHeader>
					<tr>
						<Styled.CheckboxTh>
							<Checkbox
								onChange={() => {
									onSelectAll(), setShowSelectedInfluencers(false);
								}}
								checked={isSelected}
								isIndeterminate={selectedItems.length < list.length}
							/>
						</Styled.CheckboxTh>
						{TABLE_FIELDS.map(({ property, label }) => (
							<Styled.Th key={property}>
								<Styled.TableHeaderInnerWrapper data-sort-property={property} className={classNames({ followers: label === '' })}>
									{label}&nbsp;
								</Styled.TableHeaderInnerWrapper>
							</Styled.Th>
						))}
						<Styled.Th />
					</tr>
				</Styled.TableHeader>
				<Styled.TableBody data-testid='discovery-table-body'>
					{loading ? (
						<tr>
							<td colSpan={100} />
						</tr>
					) : (
						list.map((influencer, index) => {
							const isSelected = selectedItems.some((item) => item === influencer.id);
							return (
								<InfluencerListItem
									dataTestId={`influenceritem-${index}`}
									key={`${influencer.id}-${index}`}
									data={influencer}
									onSelect={() => onSelectOne()(influencer.id)}
									isSelected={isSelected}
									isOdd={(index + 1) % 2 === 1}
									whyDoIGetLink={influencer.links.explain}
								/>
							);
						})
					)}
				</Styled.TableBody>
			</Styled.Table>
			{isLoadingNext && (
				<Styled.LoadingSpinnerWrapper>
					<LoadingSpinner size='sm' position='center' />
				</Styled.LoadingSpinnerWrapper>
			)}
			{/* Only show load more if there could be more items */}
			{!loading && !isLoadingNext && list.length && list.length % 100 == 0 && (
				<Styled.LoadMoreWrapper>
					<hr />
					<Styled.LoadMoreButton onClick={loadMore}>Load more</Styled.LoadMoreButton>
				</Styled.LoadMoreWrapper>
			)}
		</>
	);
};

export default ResultTable;
