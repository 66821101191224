import { useContext } from 'react';

import { Heading } from 'components/Heading';
import DiscoveryContext from 'contexts/Discovery';

import Styled from './SearchExamples.style';
import { Searches } from './helpers';

const SearchExamples = () => {
	const { changeSearchValueHandler, filter, setFilter } = useContext(DiscoveryContext);

	const handleSearch = (text: string) => {
		setFilter({ ...filter, countries: 'SWE', audienceCountries: 'SWE' });
		changeSearchValueHandler({ text: text });
	};

	return (
		<Styled.ExamplesWrapper>
			<Styled.Text>
				<Heading as='h5'>Find your ideal match</Heading>
				<p>
					Describe the content you're looking for or search by @username to discover the perfect match. Below, we've listed some popular searches to get you
					started.
				</p>
			</Styled.Text>
			<Styled.CardContainer>
				{Searches.map((search, index) => {
					return (
						<Styled.CardWrapper key={index} onClick={() => handleSearch(search.inputValue)}>
							<Styled.Card img={search.img}></Styled.Card>
							<Styled.SearchText>
								<span>{search.inputValue}</span>
							</Styled.SearchText>
						</Styled.CardWrapper>
					);
				})}
			</Styled.CardContainer>
		</Styled.ExamplesWrapper>
	);
};

export default SearchExamples;
